import { DictionaryService } from '@alliance/jobseeker/api'
import { Environment } from '@alliance/shared/environment'
import { log } from '@alliance/shared/logger'
import { CountryCodesEnum, TranslationService } from '@alliance/shared/translation'
import { IsNewRobotaDomainService } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { combineLatest, Observable, of } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { ListItem, Thing, WithContext } from 'schema-dts'
import { SeoParamsResponseHrefLangModel } from '../openapi/model/seo-params-response-href-lang.model'
import { ExceptionsRuKeywords } from './exceptions-keywords-ru'
import { ExceptionsUaKeywords } from './exceptions-keywords-ua'
import { PlatformHosts, UrlSegmentCityItem } from './models'
import { Translations } from './localization/translations'
import LangCodeEnum = SeoParamsResponseHrefLangModel.LangCodeEnum

@Injectable({ providedIn: 'root' })
export class HelpersService {
  public constructor(
    private dictionaryService: DictionaryService,
    private translations: Translations,
    private translationService: TranslationService,
    private env: Environment,
    private isNewRobotaDomainService: IsNewRobotaDomainService
  ) {}

  public createURL(platformUrl: PlatformHosts, path = '', langCode: LangCodeEnum | null = null): string {
    let langPath: string
    if (this.isNewRobotaDomainService.isNewRobotaDomain()) {
      langPath = (langCode ?? this.translationService.getCurrentLang()) === SeoParamsResponseHrefLangModel.LangCodeEnum.Uk ? '' : 'ru'
    } else {
      langPath = (langCode ?? this.translationService.getCurrentLang()) === SeoParamsResponseHrefLangModel.LangCodeEnum.Uk ? 'ua' : ''
    }
    return new URL(langPath + '/' + path.replace(/^\/+/g, '' /* remove leading slash */), this.getPlatformHost(platformUrl)).toString().replace(/\/$/, '' /* remove trailing slash */)
  }

  public createHrefLangURLs(urlForMobile?: string, urlForDesktop?: string): SeoParamsResponseHrefLangModel[] {
    return [SeoParamsResponseHrefLangModel.LangCodeEnum.Uk, SeoParamsResponseHrefLangModel.LangCodeEnum.Ru].map(langCode => ({
      langCode,
      mobileUrl: urlForMobile ? this.createURL(PlatformHosts.desktop, urlForMobile, langCode) : '',
      desktopUrl: urlForDesktop ? this.createURL(PlatformHosts.desktop, urlForDesktop, langCode) : ''
    }))
  }

  public getEmployerHomePageBreadcrumb(position = 1): { '@type': string; position: number; name: string; item: string } {
    return {
      '@type': 'ListItem',
      position,
      name: this.translationService.translate(this.translations.jsonLd.breadcrumbs.home),
      item: this.createURL(PlatformHosts.desktop, '/my')
    }
  }

  public getCandidatesBreadcrumb(_: PlatformHosts, position = 2): ListItem {
    return {
      '@type': 'ListItem',
      position,
      name: this.translationService.translate(this.translations.jsonLd.breadcrumbs.resume),
      item: this.createURL(PlatformHosts.desktop, '/candidates')
    }
  }

  public getHomePageBreadcrumb(platform: PlatformHosts, position = 1): ListItem {
    return {
      '@type': 'ListItem',
      position,
      name: this.translationService.translate(this.translations.jsonLd.breadcrumbs.home),
      item: this.createURL(platform, '/')
    }
  }

  public getSearchByVacanciesBreadcrumb(platform: PlatformHosts, position = 2): ListItem {
    return {
      '@type': 'ListItem',
      position,
      name: this.translationService.translate(this.translations.jsonLd.breadcrumbs.vacancies),
      item: this.createURL(platform, '/jobsearch/rubrics')
    }
  }

  public createJsonLd<T extends Thing>(_: PlatformHosts, data: WithContext<T> | Array<WithContext<T>> | object = {}): string {
    return JSON.stringify([...(Array.isArray(data) ? data : [data])])
  }

  public encodeKeyword(keyword: string): string {
    return keyword
      ? `${encodeURIComponent(keyword.trim())
          /* https://stackoverflow.com/a/39707642/14137588 */
          .replace(/'/g, '%27')
          .replace(/\(/g, '%28')
          .replace(/\)/g, '%29')}`
      : ''
  }

  public decodeURISafe(value: string): string {
    if (!value) {
      return ''
    }
    let decodeUri: string
    try {
      // replace % - https://stackoverflow.com/questions/7449588/why-does-decodeuricomponent-lock-up-my-browser
      decodeUri = decodeURI(value.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'))
    } catch (e) {
      decodeUri = value
      log.warn({ where: 'core-seo: HelpersService', category: 'try_catch', message: 'decodeURISafe failed', error: e, decodeUri })
    }
    return decodeUri
  }

  public parseKeyWords(keyWordsUrlSegment: string = ''): string {
    return decodeURIComponent(keyWordsUrlSegment || '')
      .replace(/-/gi, ' ')
      .replace(/^all$/i, '')
      .toLowerCase()
  }

  public getUrlFriendlyCityName$(cityId: number, allUkraine?: string): Observable<string> {
    if (cityId === 0) {
      return of(allUkraine ? allUkraine : 'ukraine')
    }
    return this.dictionaryService.getCitiesAndRegionsName$(cityId, 'en').pipe(map(cityName => this.getUrlCitySegment(cityName)))
  }

  public getUrlCitySegment(cityName: string | undefined): string {
    return (cityName ?? '')
      .replace(/\s\(.*\)/gi, '') // убираем старое название города, пример: "Троицкое (Довгалевское), Киевская обл."
      .replace(/,\s|\s/gi, '_') // разделители делаем андерскором, пример: "Александровка, Донецкая обл."
      .replace('.', '') // убираем точку после "обл."
      .toLowerCase()
  }

  public getCityByUrlSegment$(urlCitySegment: string): Observable<UrlSegmentCityItem | undefined> {
    const city = urlCitySegment === 'ukraine' ? 'all_ukraine' : urlCitySegment
    return this.dictionaryService.getCityListAndRegions$().pipe(
      filter(list => !!list),
      map(list =>
        list.map(item => ({
          ...item,
          urlSegment: this.getUrlCitySegment(item.en)
        }))
      ),
      map((list: UrlSegmentCityItem[]) => list.find(item => item.urlSegment === city))
    )
  }

  public createDesktopVacancyListUrl$(cityId: number, keyword: string, page?: number): Observable<string> {
    return this.createDesktopVacancyListPathName$(cityId, keyword, page).pipe(map(pathName => this.createURL(PlatformHosts.desktop, pathName)))
  }

  public createDesktopCandidatesListUrl$(cityId: number, keyword: string, page?: number): Observable<string> {
    return this.createCandidatesListPathName$(cityId, keyword, page).pipe(map(pathName => this.createURL(PlatformHosts.desktop, pathName)))
  }

  public getTransliterationKeywordToLatin$(keyword: string): Observable<string> {
    return !keyword
      ? of('')
      : this.dictionaryService.getKeywordsWithTransliteration().pipe(
          map(list => {
            const transliterationItem = list.find(item => item.name?.toLowerCase() === keyword.toLowerCase())
            return transliterationItem?.transliteredName?.toLowerCase() ?? keyword.toLowerCase()
          })
        )
  }

  public getTransliterationKeywordToCyrillic$(keyword: string): Observable<string> {
    return !keyword
      ? of('')
      : this.dictionaryService.getTransliterationKeywordToCyrillic(keyword.replace(/\s+/g, '-')).pipe(
          map(data => {
            const transliterationItem = (data?.list ?? [])
              .sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? '', 'ru', { sensitivity: 'base' }))
              .find(item => this.parseKeyWords(item.transliteredName) === keyword.toLowerCase())
            return this.getCyrillicLocalKeyword(transliterationItem?.name?.toLowerCase() ?? keyword.toLowerCase())
          })
        )
  }

  public getCyrillicLocalKeyword(keyword: string): string {
    const replacedKeyword = keyword.replace(/\s{2,}/g, ' ')
    return this.translationService.currentLangIsUkrainian()
      ? ExceptionsUaKeywords.find(item => item.keyword === replacedKeyword)?.localKeyword ?? replacedKeyword
      : ExceptionsRuKeywords.find(item => item.keyword === replacedKeyword)?.localKeyword ?? replacedKeyword
  }

  public createCandidatesListPathName$(cityId: number, keyword: string, page?: number): Observable<string> {
    const pageSuffix = page && page > 1 ? `?page=${page}` : ''

    return combineLatest([this.getUrlFriendlyCityName$(cityId), this.getTransliterationKeywordToLatin$(keyword)]).pipe(
      map(([cityUrl, transliterationKeyword]) => {
        if (!transliterationKeyword?.trim() && cityId > 0) {
          return '/candidates/all/' + cityUrl + pageSuffix
        }
        const keywordFragment = (transliterationKeyword?.trim()?.toLowerCase() || 'all').replace(/\//gi, ' ').replace(/\s/gi, '-').replace(/ - /gi, '-')
        return `/candidates/${this.encodeKeyword(keywordFragment)}/${cityUrl}${pageSuffix}`
      })
    )
  }

  public createDesktopVacancyListPathName$(cityId: number, keyword: string, page?: number): Observable<string> {
    const pageSuffix = page && page > 1 ? `?page=${page}` : ''

    return combineLatest([this.getUrlFriendlyCityName$(cityId), this.getTransliterationKeywordToLatin$(keyword)]).pipe(
      map(([cityUrl, transliterationKeyword]) => {
        if (!transliterationKeyword?.trim() || keyword === 'all') {
          return `/zapros/${cityUrl}${pageSuffix}`
        }

        const keywordFragment = transliterationKeyword?.trim()?.toLowerCase().replace(/\//gi, ' ').replace(/\s/gi, '-')
        return `/zapros/${this.encodeKeyword(keywordFragment)}/${cityUrl}${pageSuffix}`
      })
    )
  }

  public getPlatformHost(platform: PlatformHosts): string {
    switch (platform) {
      case PlatformHosts.desktop:
        return (this.env.mainSiteUrl ?? '').replace(/\/$/, '')
      case PlatformHosts.accountHost:
        return this.env.accountHost ?? ''
      default:
        return ''
    }
  }

  public getLanguageUrl(path: string): string {
    if (this.translationService.currentLangIsUkrainian()) {
      const url = path.replace(/\/$/, '').split('/')
      url.splice(3, 0, CountryCodesEnum.UA)
      return url.join('/')
    } else {
      return path
    }
  }
}
