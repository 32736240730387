import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { OverlayModule } from '@angular/cdk/overlay'
import { PortalModule } from '@angular/cdk/portal'
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus'

import { SharedOuterClickDirectiveModule } from '@alliance/shared/directives/outer-click'
import { SharedPipesContextToImplicitModule } from '@alliance/shared/pipes/context-to-implicit'
import { SharedPipesBypassSanitizerModule } from '@alliance/shared/pipes/bypass-sanitizer'

import { ModalComponent } from './components/modal/modal.component'
import { VerticalModalComponent } from './components/vertical-modal/vertical-modal.component'
import { MobileOverlayModalComponent } from './components/mobile-overlay-modal/mobile-overlay-modal.component'
import { SnackbarComponent } from './components/snackbar/snackbar.component'
import { MobileFullScreenModalComponent } from './components/mobile-full-screen-modal/mobile-full-screen-modal.component'
import { FixedModalComponent } from './components/fixed-modal/fixed-modal.component'
import { SharedWebApisMutationObserverModule } from '@alliance/cdk/viewport'

@NgModule({
  imports: [
    CommonModule,
    PolymorpheusModule,
    OverlayModule,
    PortalModule,
    SharedOuterClickDirectiveModule,
    SharedPipesContextToImplicitModule,
    SharedPipesBypassSanitizerModule,
    SharedWebApisMutationObserverModule
  ],
  exports: [MobileOverlayModalComponent, SnackbarComponent, MobileFullScreenModalComponent, FixedModalComponent, VerticalModalComponent],
  declarations: [ModalComponent, VerticalModalComponent, MobileOverlayModalComponent, SnackbarComponent, MobileFullScreenModalComponent, FixedModalComponent]
})
export class SharedSantaModule {}
