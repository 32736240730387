import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Environment } from '@alliance/shared/environment'

@Injectable()
export class OperationQueryInterceptor implements HttpInterceptor {
  public constructor(private env: Environment) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes(this.env.graphqlApi ?? '')) {
      const body = (req?.body as { operationName: string }) || ''

      const operationName: string = body && typeof body === 'object' && 'operationName' in body ? body.operationName : ''

      req = req.clone({
        params: new HttpParams().append('q', operationName)
      })
    }

    return next.handle(req)
  }
}
